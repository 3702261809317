<template>
    <div class="property-step">
        <!-- <h2 class="property-step__title">مخططات العقار</h2> -->
        <div class="form">
            <table id="floorplans">
                <tbody class="ui-sortable">
                    <tr v-for="(layout, index) in bluePrint" :key="index" class="floorplans-submit-item active">
                        <td>
                            <div class="fm-move">
                                <div>
                                    <i class="icon hi-nav"></i>
                                    <div class="floorplan-title">{{layout.title}}</div>
                                </div>
                                <div>
                                    <span class="fp-btn edit" @click.prevent="toggleVisible(layout)">
                                        <i class="hi-angle-down"></i>
                                    </span>
                                    <span class="fp-btn remove" @click.prevent="deleteLayout(index, layout.id)">
                                        <i class="hi-trash"></i>
                                    </span>
                                </div>
                            </div>
                            <div v-show="layout.visible" class="fm-inner-container"
                                :class="{'has-no-image' : invalidLayout}">
                                <div class="upload-area" :class="{'has-images': layout.image}" v-cloak
                                    @drop.prevent="addImagesOnDrag($event, layout.id, index)" @dragover.prevent>
                                    <div v-if="uploading" id="loading-icon" class="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div v-else class="container">
                                        <label>
                                            <input type="file" :id="`layoutImage-${layout.id}`"
                                                :ref="`layoutImageRef-${layout.id}`" accept="image/*"
                                                v-on:change="addImagesOnClick(layout.id, index)" />
                                        </label>
                                        <div class="preview-area-wrap" v-if="layout.image">
                                            <div class="preview-area">
                                                <div class="thumbnail-wrap">
                                                    <img class="preview thumbnail preview-image"
                                                        v-if="typeof(layout.image) == 'string' && layout.image.includes('amazonaws.com')"
                                                        :src="layout.image" />
                                                    <img v-else class="preview thumbnail"
                                                        :ref="`preview-image-${layout.id}`"
                                                        :id="`preview-image-${layout.id}`" :src="layout.preview" />
                                                    <span class="remove-btn hi-trash" v-if="layout.image"
                                                        v-on:click="removeImage(layout.id, index)" v-b-tooltip.hover
                                                        title="حذف الصورة">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="drag-drop-mes">
                                            <span class="icon hi-house-layout"></span>
                                            <h3>اسحب صورة المخطط هنا، أو اضغط على زر التحميل </h3>
                                        </div>
                                        <br />
                                        <div class="upload-btn-wrap">
                                            <b-button class="button btn" variant="primary"
                                                @click="fireFileInput(layout.id)"><i class="hi-plus"></i> إضافة مخطط
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="fm-inputs">
                                    <div class="fm-input floorplans-name">
                                        <label for="title">اسم الدور <span class="layout-text-length"
                                                v-text="'('+(maxTitle - layout.title.length) + ' حرف)'"></span></label>
                                        <b-form-input :id="`title-${layout.id}`" :maxlength="maxTitle"
                                            v-model="layout.title" type="text" placeholder="اسم الدور"></b-form-input>
                                    </div>
                                    <div class="fm-input floorplans-desc">
                                        <label for="desc">وصف المخطط (<span class="layout-text-length"
                                                v-text="'('+(maxDesc - layout.desc.length) + ' حرف)'"></span></label>
                                        <b-form-textarea :id="`desc-${layout.id}`" :maxlength="maxDesc"
                                            v-model="layout.desc" placeholder="وصف المخطط" rows="2" max-rows="2">
                                        </b-form-textarea>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="button-wrap" v-if="bluePrint.length < 5">
                <a class="button add-floorplans-submit-item" @click="addLayout">
                    <i class="hi-plus" aria-hidden="true"></i>
                    <span>اضافة مخطط دور جديد</span>
                </a>
            </div>
            <!-- <div style="direction:ltr">{{bluePrint}}</div> -->
        </div>
    </div>
</template>
<script>
    // import UploadLayoutImage from '@/common/UploadLayoutImage';
    import config from '@/config';
    import {
        mapMutations
    } from 'vuex';
    import {
        realEstateService
    } from "../../services";
    import Vue from 'vue';
    export default {
        props: ['property'],
        data() {
            return {
                uploading: false,
                bluePrint: [],
                // bluePrintPreviews: [],
                invalidLayout: false,
                maxTitle: 50,
                maxDesc: 180,
            };
        },
        watch: {
            property() {
                if (this.property && this.property.bluePrint) {
                    this.bluePrint = this.property.bluePrint;
                    for (let i in this.bluePrint) {
                        this.bluePrint[i].visible = true;
                        this.bluePrint[i].id = this.bluePrint[i]._id;
                    }
                }
            },
            bluePrint(layouts) {
                let length = layouts.length >= 1 ? 1 : layouts.length;
                let stepPercentage = 10 / 1 * length;
                this.setStep5_Percentage(stepPercentage);
            }
        },
        computed: {
            supportedImageTypes() {
                return config.supportedImageTypes;
            },
        },
        methods: {
            ...mapMutations('realEstateState', ['setStep5_Percentage']),
            addLayout() {
                let randId = Math.floor((Math.random() * 100) + 1);
                this.bluePrint.push({
                    id: randId,
                    title: '',
                    desc: '',
                    image: '',
                    preview: '',
                    visible: true,
                });
            },
            deleteLayout(index) {
                this.bluePrint.splice(index, 1);
            },
            // Upload Layout Image methods -----------------------------------
            fireFileInput(id) {
                this.$refs[`layoutImageRef-${id}`][0].click();
            },
            addImagesOnDrag(e, id, index) {
                this.addImages(e.dataTransfer.files, id, index);
            },
            addImagesOnClick(id, index) {
                this.addImages(this.$refs[`layoutImageRef-${id}`][0].files, id, index);
            },
            addImages(files, id, index) {
                if (!files) return;
                ([...files]).forEach(f => {
                    if (this.isValidImageType(f.type)) {
                        var form = new FormData();
                        form.append("blueprint", f);
                        this.uploading = true;
                        realEstateService.uploadRealEstateBlueprint(form).then((response) => {
                            this.uploading = false;
                            if (response.data.success) {
                                this.bluePrint[index].image = response.data.success.Location;
                            }
                        }).catch(e => {
                            console.log(e.message);
                            this.uploading = false;
                            return this.$toasted.show(' نوع الصورة غير مدعوم ', {
                                duration: 2000,
                                type: 'error',
                                icon: 'fa-times'
                            });
                        });
                    } else {
                        this.$toasted.show(' نوع الصورة ' + f.name + ' غير مدعوم ', {
                            duration: 2000,
                            type: 'error',
                            icon: 'fa-times'
                        });
                    }
                });
                this.getImagePreviews(files[0], id, index);
            },
            isValidImageType(type) {
                let isValid = this.supportedImageTypes.includes(type);
                return isValid;
            },
            removeImage(id, index) {
                this.bluePrint[index].image = '';
                this.bluePrint[index].preview = '';
            },
            getImagePreviews(image, id, index) {
                if (/\.(jpe?g|png|gif)$/i.test(image.name)) {
                    let reader = new FileReader();
                    reader.addEventListener(
                        'load',
                        function () {
                            // this.$refs[`preview-image-${id}`][0].src = reader.result;
                            this.bluePrint[index].preview = reader.result;
                            // this.bluePrintPreviews.push(reader.result);
                        }.bind(this),
                        false
                    );
                    reader.readAsDataURL(image);
                }
            },
            toggleVisible(layout) {
                let index = this.bluePrint.findIndex(item => item.id === layout.id);
                Vue.set(this.bluePrint, index, {
                    ...layout,
                    visible: !layout.visible
                });
            },
            validate() {
                for (let i = 0; i < this.bluePrint.length; i++) {
                    if (!this.bluePrint[i].image) {
                        this.invalidLayout = true;
                        this.$toasted.show('من فضلك تأكد من إضافة صور للمخططات', {
                            type: 'error',
                            duration: 2000,
                            icon: 'hi-close'
                        });
                        return false;
                    }
                }
                if (this.bluePrint) {
                    const layouts = this.bluePrint.map(item => ({
                        title: item.title,
                        image: item.image,
                        desc: item.desc,
                        preview: item.preview
                    }));
                    // this.$emit('on-validate', { 'bluePrint' : layouts, 'bluePrintPreviews' : this.bluePrintPreviews }, true);
                    this.$emit('on-validate', {
                        'bluePrint': layouts
                    }, true);
                }
                return true;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 60px;
        height: 40px;
        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 120px;
            height: 120px;
            margin: 40px 100px 0 8px;
            border: 8px solid #52ab75;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #52ab75 transparent transparent transparent;
            &:nth-child(1) {
                animation-delay: -0.45s;
            }
            &:nth-child(2) {
                animation-delay: -0.3s;
            }
            :nth-child(3) {
                animation-delay: -0.15s;
            }
        }
        // div 
    }
    // .lds-ring 
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>