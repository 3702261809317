<template>
  <div
    class="upload-area"
    :class="{ 'has-images': images && images.length }"
    v-cloak
    @drop.prevent="addImagesOnDrag($event)"
    @dragover.prevent
  >
    <!-- <h2 class="property-step__title">صور العقار</h2> -->
    <div class="container">
      <label>
        <input
          type="file"
          id="images"
          ref="images"
          accept="image/*"
          multiple
          v-on:change="addImagesOnClick()"
        />
      </label>


      <div class="preview-area-wrap">
        <div class="preview-area">
          <image-upload v-for="file in imageFiles" :key="file.id" :image="file"
           @on-remove-upload="removeImage"
           @on-upload="addUploadedImage">
          </image-upload>
          <!-- <div
            v-for="(file, key) in imageFiles"
            :key="key"
            class="thumbnail-wrap mainImage"
          >
            <img
              class="preview thumbnail"
              v-if="file.normalImage && file.normalImage.path.includes('amazonaws.com')"
              :class="'image-' + key"
              :src="file.normalImage.path"
            />
            <img
              class="preview thumbnail"
              v-else
              :class="'image-' + key"
              v-bind:ref="'image' + parseInt(key)"
            />
            <div class="thumbnail-info">
              <span  v-if="file.normalImage" class="image-name">{{ file.normalImage.key }}</span>
              <span  v-else class="image-name">{{ file.name }}</span>
      
              <div>
                <div v-if="file.isloading" class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span
                  style="display: none"
                  :id="'check-' + file.key"
                  class="remove-btn is-main-img hi-check"
                  v-b-tooltip.hover
                  title="تم رفع الصورة"
                ></span>
                <span
                  data-image=""
                  data-small-image=""
                  data-image-key=""
                  data-small-image-key=""
                  :id="'delete-' + file.size"
                  style="display: none"
                  class="remove-btn hi-trash"
                  v-on:click="removeImage($event,key)"
                  v-b-tooltip.hover
                  title="حذف الصورة"
                ></span>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <template>
        <div class="drag-drop-mes">
          <span class="icon" :class="uploadIcon"></span>
          <h3>{{ uploadTitle }}</h3>
          <p>{{ uploadDesc }}</p>
        </div>
        <br />
        <div class="upload-btn-wrap">
          <b-button
            class="button btn"
            variant="primary"
            @click="fireFileInput()"
            ><i class="hi-plus"></i>
            {{ uploadButtonText }}
          </b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// Config File
import config from "@/config";
import store from '@/store'
import { mapGetters, mapMutations } from "vuex";
import ImageUpload from '../property/ImageUpload.vue';
export default {
  props: [
    "property",
    "name",
    "uploadIcon",
    "uploadTitle",
    "uploadDesc",
    "uploadButtonText",
    "type",
  ],
  components:{ImageUpload},
  data() {
    return {
      images: [],
      //imagesSmall: [],
      countAllowed: 9, // zero indexed
      mainPropertyImage: 0,
      typeName: "",
      imageFiles: [],
      imageCounter: 0
    };
  },
  watch: {
    property() {
      if (this.property.images) {
        this.images = this.property.images;
        this.imageFiles = [];
        this.property.images.forEach(image=>{
          this.imageFiles.push({
            ...image,
            id: this.imageFiles.length +1
          })
        });
        //this.imagesSmall = this.property.imagesSmall;
        //this.mainImageIndex = this.property.mainImageIndex;
      }
    },

    images(images) {
      let length = images.length >= 3 ? 3 : images.length;
      let stepPercentage = (25 / 3) * length;
      this.setStep4_Percentage(stepPercentage);
    },
    type: {
      immediate: true,
      handler(type) {
        this.typeName = this.getConfigNameById("realEstateTypes", "En", type);
      },
    },
  },
  computed: {
    ...mapGetters("accountState", ["getConfigNameById"]),
    supportedImageTypes() {
      return config.supportedImageTypes;
    },
  },
  methods: {
    ...mapMutations("realEstateState", ["setStep4_Percentage"]),
    // Adds a file
    fireFileInput() {
      this.$refs.images.click();
    },

    addImagesOnDrag(e) {
      this.addImages(e.dataTransfer.files);
    },

    addImagesOnClick() {
      this.addImages(this.$refs.images.files);
    },
    addUploadedImage(image){
      this.images.push(image);
    },
    addImages(files) {
      if (!files) return;

      [...files].forEach((f) => {
        if (
          this.isValidImageType(f.type) &&
          this.imageFiles.length <= this.countAllowed
        ) {
          f.id = this.imageFiles.length + 1;
          // set a unique id for the file
          this.imageFiles.push(f);
        } else if (
          this.isValidImageType(f.type) &&
          this.imageFiles.length > this.countAllowed
        ) {
          this.$toasted.show(
            "العدد الأقصى للصور " + (parseInt(this.countAllowed) + 1),
            {
              duration: 2000,
              type: "error",
              icon: "fa-times",
            }
          );
        } else {
          this.$toasted.show(" نوع الصورة " + f.name + " غير مدعوم ", {
            duration: 2000,
            type: "error",
            icon: "fa-times",
          });
        }
      });
     

      if (this.name == "layoutImage") {
        this.$emit("on-set-image", this.imageFiles);
      }
    },

    isValidImageType(type) {
      let isValid = this.supportedImageTypes.includes(type);

      return isValid;
    },

    removeImage(fileId,imageKey) {
      //remove image from files
      this.imageFiles =this.imageFiles.filter(function(f){
          return f.id !== fileId
      });

      //reset previewed images
      store.commit("realEstateState/deletePreviewedImages");


      //remove image from model and repopulate previewed images
      this.images = this.images.filter(function(image){
        if(image.normalImage.key !== imageKey){
          store.commit("realEstateState/previewedImages", image.normalImage.path);                    
          return image
        }
      });
      
    },

    makeMainPropertyImage(key) {
      this.mainPropertyImage = key;
    },   

    validate() {
      if (this.images.length || this.typeName == "land") {
        this.$emit(
          "on-validate",
          {
            images: this.images,
            //imagesSmall: this.imagesSmall,
            //mainImageIndex: this.mainPropertyImage,
          },
          true
        );
        return true;
      } else {
        this.$toasted.show("يجب عليك علي الاقل اضافه صوره واحده!", {
          duration: 2000,
          type: "error",
          icon: "hi-close",
        });
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 17px;
  div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0 8px 0 8px;
  border: 4px solid #52ab75;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #52ab75 transparent transparent transparent;
  &:nth-child(1) {
  animation-delay: -0.45s;
}
&:nth-child(2) {
  animation-delay: -0.3s;
}
:nth-child(3) {
  animation-delay: -0.15s;
}

}
}
// .lds-ring 
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>