<template>
  <div>
    <div class="gmap-search-bar mb-5">
      <p class="mb-2 mt-3">
        ابدأ بكتابة موقع العقار ليتم اقتراح العنوان المناسب
      </p>
      <label class="d-flex justify-center autosuggest__input-wrap">
        <gmap-autocomplete
          class="autosuggest__input form-control"
          :value="address"
          :class="{ 'has-error': hasError }"
          @place_changed="setPlace"
          :options="autocompleteOptions"
        >
        </gmap-autocomplete>
        <i class="hi-map-pin icon"></i>
      </label>
      <p v-if="hasError" class="invalid-feedback d-block">من فضلك حدد المكان</p>
    </div>
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="14"
      style="width:100%;  height: 400px;"
      :options="{
        streetViewControl: false,
        mapTypeControl: false
      }"
    >
      <gmap-marker
        :position="center"
        :clickable="true"
        :draggable="true"
        @dragend="getFormattedAddress($event.latLng)"
        @click="center = m.position"
      />
    </gmap-map>

    <div class="mt-4 overflow-auto" v-if="addressInnerDetails">
      <!-- City -->
      <div class="col-sm-4">
        <div class="form">
          <label>المدينه*</label>
          <b-form-input v-model.number="city"></b-form-input>
        </div>
      </div>

      <!-- province -->
      <div class="col-sm-4">
        <div class="form">
          <label>المنطقه*</label>
          <b-form-input v-model.number="province"></b-form-input>
        </div>
      </div>

      <!-- District -->
      <div class="col-sm-4">
        <div class="form">
          <label>الحي*</label>
          <b-form-input v-model.number="district"></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import { mapMutations } from 'vuex'

export default {
  name: 'GoogleMap',
  props: {
    currentAddress: {},
    profile: {},
    addressInnerDetails: {
      default: false
    }
  },
  data() {
    return {
      city: '',
      province: '',
      district: '',
      center: { lat: 24.448378, lng: 39.586848 },
      currentPlace: null, // null
      hasError: false,
      address: '',
      autocompleteOptions: {
        componentRestrictions: {
          country: ['sa']
        }
      }
    }
  },

  watch: {
    currentAddress() {
      if (this.currentAddress) {
        this.setStep2_Percentage(15)
        this.address = this.currentAddress.address
        this.currentPlace = {
          lat: this.currentAddress.coordinates[1],
          lng: this.currentAddress.coordinates[0],
          address: this.currentAddress.address
        }
        this.center = {
          lat: this.currentAddress.coordinates[1],
          lng: this.currentAddress.coordinates[0]
        }
        this.city = this.currentAddress.city
        this.district = this.currentAddress.district
        this.province = this.currentAddress.province
      }
    },
    profile: {
      immediate: true,
      handler() {
        if (this.profile?.coordinates) {
          this.address = this.profile.address
          this.currentPlace = {
            lat: this.profile.coordinates.lat,
            lng: this.profile.coordinates.lng,
            address: this.profile.address
          }
          this.center = {
            lat: this.profile.coordinates.lat,
            lng: this.profile.coordinates.lng
          }
        }
      }
    },
    currentPlace() {
      this.$emit('on-validate', this.collectedTabData)
    }
  },

  computed: {
    google: gmapApi,
    id() {
      return this.$route.params.id
    },
    collectedTabData() {
      const data = {
        place: this.currentPlace,
        city: this.city,
        province: this.province,
        district: this.district
      }
      return data
    }
  },
  mounted() {
    if (this.id == 'new') {
      this.geolocate()
    }
  },
  methods: {
    // receives a place object via the autocomplete component
    ...mapMutations('realEstateState', ['setStep2_Percentage']),
    setPlace(location) {
      if (location) {
        this.address = location.formatted_address
        this.city = location.address_components[3]?.long_name
        this.province = location.address_components[2]?.long_name
        this.district = location.address_components[1]?.long_name
        this.currentPlace = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
          address: this.address
        }
        this.center = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng()
        }
        this.hasError = false

        this.setStep2_Percentage(15)
      }
    },

    getFormattedAddress(location) {
      // Convert the latlang to formatted address
      var geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ latLng: location }, results => {
        this.currentPlace = {
          lat: location.lat(),
          lng: location.lng(),
          address: results[0].formatted_address
        }
        this.city = results[0].address_components[3].long_name
        this.province = results[0].address_components[2].long_name
        this.district = results[0].address_components[1].long_name
        this.address = results[0].formatted_address
      })
    },

    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    },

    validate() {
      let isValid
      if (this.currentPlace) {
        isValid = true
        this.hasError = false
      } else {
        isValid = false
        this.hasError = true
      }
      this.$emit('on-validate', this.currentPlace, isValid)
      return isValid
    }
  }
}
</script>
