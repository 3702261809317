<template>
  <div class="height-mobile-fix">
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2 v-if="id == 'new'">اضافة عقار جديد</h2>
                <h2 v-else>تعديل عقار</h2>
                <span>اكتمال تفاصيل العقار يعني استهداف الكثير من العملاء</span>
                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'home' }">الرئيسية</router-link>
                    </li>
                    <li v-if="id == 'new'">اضافة عقار جديد</li>
                    <li v-else>تعديل عقار</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" alt="Parallax Image" />
      </parallax>
    </div>
    <!-- Will remove -->
    <div class="container submit-page mb-5">
      <!-- <div v-if="isExceededRealEstatesQuota" class="row mb-5 py5">
        <div class="col-sm-12 py-5">
          <b-alert class="my-5" show variant="danger">
            <b> لا يمكن إضافة عقار، تخطيت الحد المسموح لإضافة عقار !</b>
            <br />
            <b
              >يمكنك الاشتراك في احد باقات
              <a href="https://hectarplus.io/" target="_blank">هكتار بلس</a>
              للحصول على عدد اكثر من العقارات</b
            >
          </b-alert>
        </div>
      </div> -->
      <div class="row"> 
        <!-- Submit Page -->
        <div class="col-sm-12">
          <!-- Property Wizard -->
          <form-wizard
            :key="componentsKey"
            v-if="!successAdded"
            shape="circle"
            ref="addWizard"
            title
            subtitle
            next-button-text="التالي"
            back-button-text="السابق"
            finish-button-text="معاينة"
            step-size="xs"
            color="#3cab74"
            error-color="#e74c3c"
            @on-complete="onComplete"
          >
            <!-- Step #1 ----------------------------------------------------->
            <tab-content
              :before-change="() => validateStep('step1')"
              title="تفاصيل أساسية"
              icon="hi-apartment"
            >
              <h3 class="main-step-title hidden-md-up">تفاصيل أساسية</h3>
              <step1
                ref="step1"
                :property="property"
                @on-validate="collectData"
              ></step1>
            </tab-content>

            <!-- Step #2 ----------------------------------------------------->
            <tab-content
              title="الموقع الجغرافي"
              icon="hi-map-pin"
              :before-change="() => validateStep('step4')"
            >
              <h3 class="main-step-title hidden-md-up">الموقع الجغرافي</h3>
              <div class="property-step">
                <!-- <h2 class="property-step__title">حدد الموقع الجغرافي للعقار</h2> -->
                <google-maps-search
                  ref="step4"
                  :currentAddress="address"
                  @on-validate="setCurrentPlace"
                  :addressInnerDetails="addressInnerDetails"
                ></google-maps-search>
              </div>
            </tab-content>

            <!-- Step #3 ----------------------------------------------------->
            <tab-content
              :before-change="() => validateStep('step2')"
              title="خصائص ومميزات"
              icon="hi-road-sign"
            >
              <h3 class="main-step-title hidden-md-up">خصائص ومميزات</h3>
              <step2
                ref="step2"
                :property="property"
                :type="finalModel.type"
                @on-validate="collectData"
              >
              </step2>
            </tab-content>

            <!-- Step #4 ----------------------------------------------------->
            <tab-content
              title="صور العقار"
              icon="hi-image"
              :before-change="() => validateStep('step3')"
            >
              <h3 class="main-step-title hidden-md-up">صور العقار</h3>
              <step3
                ref="step3"
                :property="property"
                @on-validate="collectData"
                :type="finalModel.type"
                name="proprty-images"
                uploadIcon="hi-images"
                uploadTitle="اسحب الصور هنا لرفعها أو اضغط على زر إضافة الصور"
                uploadDesc="يمكنك الإضافة حتى ١٠ صور لعقارك"
                uploadButtonText="إضافة الصور"
              >
              </step3>
            </tab-content>

            <!-- Step #5 ----------------------------------------------------->
            <tab-content
              :before-change="() => validateStep('add-layout')"
              title="المخططات"
              icon="hi-house-layout"
            >
              <h3 class="main-step-title hidden-md-up">المخططات</h3>
              <add-layouts
                ref="add-layout"
                :property="property"
                @on-validate="collectData"
              ></add-layouts>
            </tab-content>

            <!-- Step #6 ----------------------------------------------------->
            <tab-content
              title="معاينة العقار"
              class="preview-step-wrap"
              icon="hi-preview"
            >
              <h3 class="main-step-title hidden-md-up">معاينة العقار</h3>
              <previewStep
                ref="previewstep"
                :property-data="finalModel"
              ></previewStep>
            </tab-content>

            <div
              id="add-property-progress"
              class="progress-indecator"
              :class="{ 'zero-complete': totalCompletePercentage == 0 }"
            >
              <span class="complete-persentage-text">نسبة اكتمال</span>
              <Progress
                :radius="mq === 'sm' || mq === 'xs' ? 18 : 22"
                :strokeWidth="3"
                :value="totalCompletePercentage"
                :transitionDuration="200"
                strokeColor="#3cab74"
              ></Progress>
              <span class="complete-persentage-text">تفاصيل العقار</span>
            </div>

            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <b-button
                  v-if="props.activeTabIndex > 0"
                  size="lg"
                  variant="light"
                  @click.prevent="props.prevTab()"
                  class="prev-btn"
                >
                  <span
                    ><span class="icon hi-angle-right ml-4"></span> السابق</span
                  >
                </b-button>
              </div>

              <div class="wizard-footer-right">
                <b-button
                  v-if="!props.isLastStep"
                  size="lg"
                  variant="primary"
                  class="wizard-footer-right"
                  @click.prevent="props.nextTab()"
                >
                  <span v-if="props.activeTabIndex == 4"
                    ><span class="icon hi-eye ml-3"></span> معاينة العقار</span
                  >
                  <span v-else
                    >التالي <span class="icon hi-angle-left mr-4"></span
                  ></span>
                </b-button>

                <div v-else>
                  <b-button
                    :disabled="spinnerLoading"
                    size="lg"
                    variant="primary"
                    class="wizard-footer-right finish-button"
                    @click.prevent="props.nextTab()"
                  >
                    <span>
                      <span class="icon hi-check-circle ml-3"></span>
                      <span v-if="id !== 'new'"> تحديث العقار </span>
                      <span v-else> نشر العقار </span>
                    </span>
                    <b-spinner
                      v-show="spinnerLoading"
                      small
                      class="mr-2"
                    ></b-spinner>
                  </b-button>
                </div>
              </div>
            </template>
          </form-wizard>

          <!-- Success Add Property -->
          <div v-else id="success-added-screen">
            <img :src="successAddedImg" alt="Success Image" />

            <div class="success-added-msg">
              <p class="success-msg" v-if="id == 'new'">
                تم إضافة عقارك بنجاح!
              </p>
              <p class="success-msg" v-else>تم تحديث عقارك بنجاح!</p>
              <p>
                شكرا لك، لقد بدأت رحلتك مع عقارك بنجاح، سيصل عقارك لأكبر عدد
                ممكن من المستخدمين.
              </p>
            </div>

            <div class="success-added-btns">
              <b-button
                class="m-2"
                variant="primary"
                :size="resizeBTn"
                @click="showAddProperty"
              >
                <span class="icon hi-plus ml-2"></span>
                <span>أضف عقار آخر</span>
              </b-button>

              <b-button
                :size="resizeBTn"
                variant="primary"
                class="m-2"
                :to="{
                  name: 'property',
                  params: { id: addedOrUpdatedPropertyId, images360: 0 }
                }"
              >
                <span class="icon hi-eye ml-2"></span>
                <span>معاينة العقار</span>
              </b-button>

              <b-button
                :size="resizeBTn"
                variant="primary"
                class="m-2"
                :to="{ name: 'properties', params: { id: user._id } }"
              >
                <span class="icon hi-apartment ml-2"></span>
                <span>عقاراتي</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="fullwidth-loading-wrap add-property-loader"
      v-if="spinnerLoading"
    >
      <b-spinner label="Spinning" variant="primary"></b-spinner>
      <div class="loading-text">
        <span v-if="id == 'new'">جاري إضافة العقار...</span>
        <span v-else>جاري تحديث العقار...</span>
      </div>
    </div>

    <!-- <div class="container" style="direction:ltr; text-align: left;">
          قيم الحقول
          <pre>{{finalModel}}</pre>
        </div> -->
  </div>
</template>

<script>
import Parallax from 'vue-parallaxy'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
//Wizard
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import Step1 from '@/components/add-property/Step1.vue'
import Step2 from '@/components/add-property/Step2.vue'
import Step3 from '@/components/add-property/Step3.vue'
import AddLayouts from '@/components/add-property/AddLayouts.vue'
import previewStep from '@/components/add-property/preview-step.vue'
import GoogleMapsSearch from '@/components/map/GoogleMapsSearch.vue'
import Progress from 'easy-circular-progress'
import { realEstateService } from '@/services'
//Style
import '@/styles/property/common-in-addproperty-and-single.scss'
import '@/styles/property/add-property.scss'

export default {
  layout: 'main',
  components: {
    Parallax,
    FormWizard,
    TabContent,
    Step1,
    Step2,
    Step3,
    AddLayouts,
    previewStep,
    GoogleMapsSearch,
    Progress
  },

  data() {
    return {
      addressInnerDetails: true,
      property: {},
      componentsKey: 0,
      titleBg: require('../../assets/images/home-parallax-2.jpg'),
      successAdded: false,
      successAddedImg: require('../../assets/images/success-added.svg'),
      // loadingWizard: true,
      // errorMsg: null,
      count: 0,
      finalModel: {},
      address: {},
      addedOrUpdatedPropertyId: '',
      realEstatesCount: 0
    }
  },
  async created() {
    if (this.$route.params.id !== 'new') {
      document.title = 'تعديل عقار'
    }
    // get the data of the property to update it
    if (this.id !== 'new') {
      const result = await realEstateService
        .getSingleProperty(this.id)
        .catch((error) => {
          this.$router.push({ name: 404 })
          this.$toasted.show(error, {
            duration: 7000,
            type: 'error',
            icon: 'hi-close'
          })
        })
      if (result.data.realEstate) {
        this.property = result.data.realEstate
        this.address = {
          coordinates: this.property.location.coordinates,
          address: this.property.propertyAddress.address,
          city: this.property.propertyAddress.city,
          district: this.property.propertyAddress.district,
          province: this.property.propertyAddress.province
        }
      }
    }
    // else {
    //   await this.getOwnerRealEstates()
    // }
  },
  mounted() {
    // prvent number field from scroll;
    var inputs = document.querySelectorAll('[type="number"]')
    inputs.forEach(function (elem) {
      elem.addEventListener('mousewheel', function () {
        this.blur()
      })
    })

    // Reset Complete indecator
    this.resetCompleteIndicator()

    //set the loader to false
    this.setSpinnerLoading(false)
  },

  computed: {
    ...mapState('userState', ['spinnerLoading']),
    ...mapState('accountState', ['user', 'realEstatesQuota']),
    ...mapState('realEstateState', [
      'step1_Percentage',
      'step2_Percentage',
      'step3_Percentage',
      'step4_Percentage',
      'step5_Percentage',
      'propertiesFound'
    ]),
    ...mapGetters('realEstateState', ['totalCompletePercentage']),
    id() {
      return this.$route.params.id
    },
    mq() {
      return this.$mq
    },
    resizeBTn() {
      return this.mq === 'md' || this.mq === 'sm' || this.mq === 'xs'
        ? 'sm'
        : 'lg'
    }
    // isExceededRealEstatesQuota() {
    //   if (this.realEstatesQuota && this.realEstatesQuota > 0) return false
    //   else return true
    // },
    // numberOfRealEstatesLeft() {
    //   return this.realEstatesQuota - this.realEstatesCount
    // }
  },

  methods: {
    ...mapActions('realEstateState', [
      'getSingleProperty',
      'addRealEstate',
      'updateRealEstate',
      'getRealEstate'
    ]),
    ...mapMutations('realEstateState', [
      'deletePreviewedImages',
      'setStep1_Percentage',
      'setStep2_Percentage',
      'setStep3_Percentage',
      'setStep4_Percentage',
      'setStep5_Percentage'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),

    // Form Wizard ------------------------------
    onComplete: function () {
      this.setSpinnerLoading(true)
      // remove the filds that used to transfere data from step to step
      delete this.finalModel.completedFields
      delete this.finalModel.propertyType
      // delete this.finalModel.bluePrintPreviews;
      if (this.finalModel.bluePrint) {
        for (let index = 0; index < this.finalModel.bluePrint.length; index++) {
          delete this.finalModel.bluePrint[index].preview
        }
      }

      if (this.finalModel.payType || this.finalModel.payType === '') {
        delete this.finalModel.payType
      }

      if (!this.finalModel.space) {
        delete this.finalModel.space
      }

      delete this.finalModel.featuresItems
      delete this.finalModel.floorText
      delete this.finalModel.unitsText

      // check if propertyy name is set or not
      this.finalModel.propertyName = this.finalModel.propertyName
        ? this.finalModel.propertyName
        : this.finalModel.propertyAddress.address

      // add the Complete Percentage to the send data.
      this.finalModel = Object.assign({}, this.finalModel, {
        completePercentage: this.totalCompletePercentage
      })

      if (this.id !== 'new') {
        this.updateProperty()
      } else {
        this.removeEmptyProperty(this.finalModel)
        this.addProperty()
      }
    },
    async getOwnerRealEstates() {
      const query = `account=${this.user?._id}&pageSize=1&pageNumber=1`
      await realEstateService.ownerRealEstates(query).then((res) => {
        this.realEstatesCount = res.data.count
      })
    },
    addProperty() {
      this.addRealEstate(this.finalModel)
        .then(
          (success) => {
            this.addedOrUpdatedPropertyId = success.data.realEstate._id
            let getPropertiesQuery = `account=${success.data.realEstate.account.id}`
            this.getRealEstate(getPropertiesQuery)
            this.$toasted.show('تم إضافة العقار بنجاح', {
              duration: 2000,
              type: 'success',
              icon: 'hi-check'
            })
            this.successAdded = true
            this.deletePreviewedImages()
          },
          (error) => {
            this.$toasted.show(error, {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            })
          }
        )
        .catch((error) => {
          this.$toasted.show(error, {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        })
        .finally(() => {
          this.setSpinnerLoading(false)
        })
    },

    updateProperty() {
      // debugger;
      this.updateRealEstate({ model: this.finalModel, id: this.id })
        .then(
          (success) => {
            // console.log('update property rsponse: ',success);
            this.addedOrUpdatedPropertyId = success.data.updatedData._id
            this.setSpinnerLoading(false)
            this.$toasted.show('تم تحديث العقار بنجاح', {
              duration: 2000,
              type: 'success',
              icon: 'hi-check'
            })
            this.successAdded = true
            this.deletePreviewedImages()
          },
          (error) => {
            // console.log(error);
            this.setSpinnerLoading(false)
            this.$toasted.show(error, {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            })
          }
        )
        .catch((error) => {
          this.$toasted.show(error, {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        })
        .finally(() => {
          this.setSpinnerLoading(false)
        })
    },

    async validateStep(name) {
      const refToValidate = this.$refs[name]
      const validateStep = await refToValidate.validate()
      return validateStep
    },
    setCurrentPlace({ place, city, province, district }) {
      // console.log('isValid: ', isValid);
      // if(place){
      //     this.collectData({'address.coordinates[0]': place.lat}, true);
      //     this.collectData({'address.coordinates[1]': place.lng}, true);
      //     this.collectData({'address.address': place.address}, true);
      //     this.collectData({'address.type': 'Point'}, true);
      // }
      if (place) {
        this.collectData(
          {
            location: {
              coordinates: {
                lat: place.lat,
                lng: place.lng
              },
              type: 'Point'
            }
          },
          true
        )
        this.collectData(
          {
            propertyAddress: {
              address: place.address,
              city,
              province,
              district
            }
          },
          true
        )
      }
    },

    collectData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      } else {
        this.$toasted.show('من فضلك تأكد من ملئ جميع الحقول المطلوبة', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
      }
    },
    removeEmptyProperty(obj) {
      return Object.keys(obj).forEach(
        (key) => obj[key] == null && delete obj[key]
      )
    },

    backToFirstStep() {
      this.$refs.addWizard.changeTab(5, 0)
    },
    resetCompleteIndicator() {
      for (let stepNumber = 1; stepNumber <= 5; stepNumber++) {
        this[`setStep${stepNumber}_Percentage`](0)
      }
    },
    showAddProperty() {
      this.resetCompleteIndicator()
      this.successAdded = false
    }
  }
}
</script>

<style lang="scss">
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}
@media (max-width: 575px) {
  .vue-form-wizard{
     .wizard-nav-pills  {
      padding-right: 1rem;
      li{
    flex-basis: 30%;
    margin-bottom: 1rem;
    .wizard-icon-circle{
       .wizard-icon-container {
    border-radius: 50%;
}
}
&.active{
  .wizard-icon-circle{
    border: 0;
  }
}
}
}
.wizard-navigation {
  .wizard-progress-with-circle {
    .wizard-progress-bar{
      min-width: 25% !important;
}
// .wizard-progress-bar
  }
}

}
// .vue-form-wizard
}

</style>

<!-- <style>

.upload-area label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
.upload-area .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.upload-area .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>  -->
