<template>
  <div class="property-step" id="preview-step">
    <div class="preview-area-wrap">
      <div class="preview-area">
        <div id="previewSlider">
          <slick
            id="single-prope-slider"
            ref="slick"
            :options="{ slidesToShow: 1, rtl: true }"
            v-if="previewedImages.length > 0"
          >
            <div
              v-for="(img, index) in getImages()"
              :key="index"
              class="listing-img-container slide-item"
            >
              <div class="slide-item">
                <div
                  class="slide-bg"
                  :style="{ backgroundImage: `url(&quot;${img}&quot;)` }"
                >
                  <div class="property-meta">
                    <span class="meta-item favorite-item mr-0 pr-0">
                      <a class="favorite-btn">
                        <span class="hi-bookmark2-o icon"></span>
                      </a>
                    </span>

                    <span class="meta-item images-count" v-if="previewedImages">
                      <i class="hi-image meta-item--icon"></i>
                      <span class="meta-item--text">{{
                        previewedImages.length
                      }}</span>
                    </span>

                    <span class="meta-item views-count">
                      <i class="hi-eye meta-item--icon"></i>
                      <span class="meta-item--text">0</span>
                    </span>

                    <span class="meta-item likes-icon">
                      <vue-star ref="likeProperty" color="#fff">
                        <a slot="icon" class="hi-thumbs-o-up"></a>
                      </vue-star>
                    </span>
                    <span class="meta-item Likes-count">0</span>
                  </div>
                </div>
              </div>
            </div>
          </slick>
          <img
            v-else
            src="../../assets/images/default-image.svg"
            lazy-src="../../assets/images/default-image.svg"
            alt="Default Image"
            class="w-100 h-75 mt-4"
          />
        </div>
      </div>
    </div>

    <div class="preview-data-area row">
      <div class="col-sm-7">
        <div v-if="propertyData">
          <div class="item-main-data mobile-main-data-card">
            <div class="main-data">
              <span>
                {{
                  getConfigNameById('realEstateTypes', 'Ar', propertyData.type)
                }}
              </span>

              <span
                v-if="
                  hasPurpose(
                    getConfigNameById(
                      'realEstateTypes',
                      'En',
                      propertyData.type
                    )
                  )
                "
              >
                {{
                  getConfigNameById(
                    'realEstatePurpose',
                    'Ar',
                    propertyData.purpose
                  )
                }}
              </span>

              <span>
                {{
                  getConfigNameById(
                    'realEstateStatus',
                    'Ar',
                    propertyData.status
                  )
                }}
              </span>
            </div>
            <div class="price-currency">
              <span class="price">
                {{ numberWithCommas(propertyData.price) }}
              </span>
              <span class="unit-item"> ريال </span>
              <span
                class="unit-item"
                v-if="
                  getConfigNameById(
                    'realEstateStatus',
                    'En',
                    propertyData.status
                  ) == 'rent' &&
                    propertyData.payType &&
                    propertyData.payType !== ''
                "
              >
                / {{ payTypeOptions[propertyData.payType].text }}
              </span>
            </div>
          </div>

          <div class="data-item">
            <span class="data-icon hi-map-pin"></span>
            <span class="data-value">{{
              propertyData.propertyAddress
                ? propertyData.propertyAddress.address
                : ''
            }}</span>
          </div>

          <div class="data-item" v-if="propertyData['space']">
            <span class="data-icon hi-area"></span>
            <span class="data-label">مساحة العقار: </span>
            <span class="data-value"
              >{{ propertyData['space'] }}
              <span class="unit-item">متر</span></span
            >
          </div>

          <div class="data-item" v-if="propertyData['populationType']">
            <span class="data-icon hi-chair"></span>
            <span class="data-label"> نوع السكن: </span>
            <span class="data-value">
              {{
                getConfigNameById(
                  'realEstatePopulationType',
                  'Ar',
                  propertyData.populationType
                )
              }}</span
            >
          </div>

          <div class="data-item" v-if="propertyData['numberOfUnit']">
            <span class="data-icon hi-apartment"></span>
            <span class="data-label">عدد الوحدات</span>
            <span class="data-value">{{ propertyData['numberOfUnit'] }}</span>
          </div>

          <div class="data-item" v-if="propertyData['floor']">
            <span class="data-icon hi-layers"></span>
            <span class="data-label">{{ floorText }}: </span>
            <span class="data-value">{{ propertyData['floor'] }}</span>
          </div>

          <div class="data-item" v-if="propertyData['age']">
            <span class="data-icon hi-calender"></span>
            <span class="data-label">عمر العقار: </span>
            <span class="data-value">{{ propertyData['age'] }}</span>
          </div>

          <div class="data-item" v-if="propertyData['numberOfRooms']">
            <span class="data-icon hi-bed"></span>
            <span class="data-label">عدد الغرف {{ unitsText }}: </span>
            <span class="data-value">{{ propertyData['numberOfRooms'] }}</span>
          </div>

          <div class="data-item" v-if="propertyData['numberOfKitchenUnit']">
            <span class="data-icon hi-platter"></span>
            <span class="data-label">عدد المطابخ {{ unitsText }}: </span>
            <span class="data-value">{{
              propertyData['numberOfKitchenUnit']
            }}</span>
          </div>

          <div class="data-item" v-if="propertyData['numberOfBathRoom']">
            <span class="data-icon hi-bathroom"></span>
            <span class="data-label">عدد دورات المياة {{ unitsText }}: </span>
            <span class="data-value">{{
              propertyData['numberOfBathRoom']
            }}</span>
          </div>

          <div class="data-item" v-if="propertyData['numberOfLivingRoom']">
            <span class="data-icon hi-hall"></span>
            <span class="data-label">عدد الصالات {{ unitsText }}: </span>
            <span class="data-value">{{
              propertyData['numberOfLivingRoom']
            }}</span>
          </div>

          <div class="data-item" v-if="propertyData['streetWidth']">
            <span class="data-icon hi-road"></span>
            <span class="data-label">عرض الشارع: </span>
            <span class="data-value"
              >{{ propertyData['streetWidth'] }}
              <span class="unit-item">متر</span></span
            >
          </div>

          <div
            class="data-item"
            v-if="
              propertyData['selectedSides'] &&
                propertyData['selectedSides'].length > 0
            "
          >
            <span class="data-icon hi-road"></span>
            <span class="data-label">الأرض مطلة على شوارع من الناحية: </span>
            <div class="fullwidth-value">
              <span
                class="data-value feature-item ml-3"
                v-for="(item, key) in propertyData['selectedSides']"
                :key="key"
                ><span class="icon hi-check ml-2"></span>
                {{ selectedSides[item] }}</span
              >
            </div>
          </div>

          <div
            class="data-item"
            v-if="
              propertyData['features'] && propertyData['features'].length > 0
            "
          >
            <span class="data-icon hi-home2"></span>
            <!-- <span class="data-label">مميزات العقار: </span> -->
            <div class="fullwidth-value">
              <span
                class="data-value feature-item ml-3"
                v-for="(item, key) in propertyData['features']"
                :key="key"
                ><span class="icon hi-check ml-2"></span>
                {{ getConfigNameById('realEstateFeatures', 'Ar', item) }}</span
              >
            </div>
          </div>

          <div class="data-item" v-if="propertyData['notes']">
            <span class="data-icon hi-info-o"></span>
            <!-- <span class="data-label">عن العقار: </span> -->
            <span class="data-value">{{ propertyData['notes'] }}</span>
          </div>

          <!-- Floorplans -->
          <!-- <div style="direction:ltr">{{propertyData}}</div> -->
          <div
            class="mt-5"
            v-if="propertyData.bluePrint && propertyData.bluePrint.length"
          >
            <h4>المخططات</h4>
            <div class="style-1 fp-accordion mt-3">
              <div class="accordion ui-accordion">
                <div
                  v-for="(layout, index) in propertyData.bluePrint"
                  :key="index"
                >
                  <h3
                    v-b-toggle="'accordion-' + index"
                    variant="info"
                    class="ui-accordion-header"
                    :class="
                      layout.visible ? 'ui-accordion-header-active' : null
                    "
                  >
                    <span v-if="layout.title">{{ layout.title }}</span>
                    <span v-else>مخطط العقار</span>
                    <i class="icon hi-angle-down"></i>
                  </h3>
                  <b-collapse
                    :id="'accordion-' + index"
                    class="ui-accordion-content"
                    role="tabpanel"
                  >
                    <div>
                      <a class="floor-pic mfp-image">
                        <!-- <img :src="propertyData.bluePrint[index].preview" /> -->
                        <img
                          :src="
                            typeof layout.image == 'string'
                              ? layout.image
                              : propertyData.bluePrint[index].preview
                          "
                        />
                      </a>
                      <p class="fp-desc p-4">{{ layout.desc }}</p>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-5 sidebar-wrapper">
        <div class="sidebar">
          <div class="item-main-data hidden-sm-down">
            <div class="main-data">
              <span>
                {{
                  getConfigNameById('realEstateTypes', 'Ar', propertyData.type)
                }}
              </span>

              <span
                v-if="
                  hasPurpose(
                    getConfigNameById(
                      'realEstateTypes',
                      'En',
                      propertyData.type
                    )
                  )
                "
              >
                {{
                  getConfigNameById(
                    'realEstatePurpose',
                    'Ar',
                    propertyData.purpose
                  )
                }}
              </span>

              <span>
                {{
                  getConfigNameById(
                    'realEstateStatus',
                    'Ar',
                    propertyData.status
                  )
                }}
              </span>
            </div>
            <div class="price-currency">
              <span v-if="propertyData.price != 'علي السوم'">
                <span class="price">
                  {{ numberWithCommas(propertyData.price) }}
                </span>
                <span class="unit-item"> ريال </span>
              </span>
              <span v-else>علي السوم</span>

              <span
                class="unit-item"
                v-if="
                  getConfigNameById(
                    'realEstateStatus',
                    'En',
                    propertyData.status
                  ) === 'rent'
                "
              >
                / {{ payTypeOptions[propertyData.payType].text }}
              </span>
            </div>
          </div>

          <div class="data-item progress-indecator-wrap mb-4">
            <div class="progress-indecator">
              <Progress
                :value="totalCompletePercentage"
                :radius="26"
                :transitionDuration="400"
                strokeColor="#3cab74"
              ></Progress>
              <div class="progress-info">
                <h4>نسبة إكتمال تفاصيل العقار</h4>
                <span
                  >كلما كانت نسبة الاكتمال اعلى، زادت فرص البيع او التأجير</span
                >
              </div>
            </div>
          </div>

          <!-- Agent Data -->
          <div class="agent-widget">
            <div class="agent-details" v-if="user">
              <h4>
                <a href="#">{{ user.name }}</a>
              </h4>
              <span>{{ user.type }}</span>
            </div>
            <div class="agent-contact">
              <a class="agent-link" href="#"><i class="hi-phone-wave"></i></a>
              <a class="agent-link" href="#"><i class="hi-whatsapp"></i></a>
            </div>
          </div>

          <!-- Rating Modal -->
          <rating-property :isRated="false" class="mt-4 mb-4"></rating-property>

          <!-- Request Service -->
          <!-- <b-button id="request-service-btn" class="button btn fullwidth" variant="primary"><i class="hi-happy"></i> إطلب إحدى خدماتنا الآن </b-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import config from '@/config'
import VueStar from 'vue-star'
import Progress from 'easy-circular-progress'
import ratingProperty from '@/components/property/ratingProperty.vue'
import { realEstateService } from '../../services' //Modals
// slick
import Slick from 'vue-slick'

import '@/styles/property/common-in-addproperty-and-single.scss'
import '@/styles/property/add-property.scss'
import '@/styles/common/slick-slider.scss'

export default {
  components: {
    Progress,
    VueStar,
    ratingProperty,
    Slick
  },
  props: ['propertyData'],
  computed: {
    ...mapState('realEstateState', ['previewedImages']),
    ...mapState('accountState', ['user']),
    ...mapGetters('accountState', ['getConfigNameById']),
    ...mapGetters('realEstateState', ['totalCompletePercentage']),
    typeData() {
      return config.typesFields[this.propertyData.type]
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    selectedSides() {
      return config.selectedSides
    },
    type() {
      return this.getConfigNameById(
        'realEstateTypes',
        'En',
        this.propertyData.type
      )
    },
    isBuildingOrTowerOrPremises() {
      return (
        this.type === 'building' ||
        this.type === 'Tower' ||
        this.type === 'Building'
      )
    },
    floorText() {
      if (this.isBuildingOrTowerOrPremises || this.type === 'villa') {
        return 'عدد الأدوار'
      } else if (this.type === 'compound') {
        return 'عدد الأدوار في كل وحدة'
      } else {
        return 'رقم الدور'
      }
    },
    unitsText() {
      if (this.isBuildingOrTowerOrPremises || this.type == 'compound') {
        return 'في كل وحدة'
      } else {
        return ''
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions)
      }
    })
  },
  methods: {
    toType(obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
    },
    getImages() {
      return this.previewedImages
    },
    coverImage() {
      if (
        this.propertyData &&
        this.propertyData.images &&
        this.propertyData.images.length > 0
      ) {
        // debugger;
        let oldImages = this.propertyData.images.filter(item => {
          return typeof item == 'string'
        })

        let mainIndex = this.propertyData.mainImageIndex,
          img

        if (oldImages.length > mainIndex) {
          img = this.propertyData.images[mainIndex]
        } else if (this.previewedImages) {
          mainIndex = mainIndex - oldImages.length
          img = this.previewedImages[mainIndex]
        }

        return { backgroundImage: `url("${img}")` }
      }

      return ''
    },

    numberWithCommas(x) {
      return realEstateService.numberWithCommas(x)
    },
    hasPurpose(type) {
      return realEstateService.hasPurpose(type)
    }
  }
}
</script>

<style>
.rating-wrap {
  pointer-events: none !important;
}
</style>
