<template>
  <div class="property-step">
    <b-form ref="secondtStep">
      <h4 class="submit-section-header">خصائص ومميزات</h4>
      <div class="submit-section">
        <div class="row with-forms">
          <div
            class="col-sm-4"
            :class="{ 'has-error': $v.advertiserType.$error }"
          >
            <label>صفة المعلن</label>
            <multiselect
              id="account-type"
              v-model="advertiserType"
              :show-labels="false"
              :class="{ 'has-error': $v.advertiserType.$error }"
              placeholder="اختر صفة المعلن"
              :options="advertiserTypes"
              track-by="_id"
              value-field="code"
              text-field="nameAr"
              label="name"
            >
              <template slot="noOptions">لا توجد عناصر</template>
            </multiselect>
            <span
              v-if="!$v.advertiserType.required"
              class="invalid-feedback d-block"
              >من فضلك اختر صفه المعلن</span
            >
          </div>

          <div
            class="col-sm-4"
            v-if="isAuthorizedClient"
            :class="{ 'has-error': $v.authorizationNumber.$error }"
          >
            <div class="form">
              <label> رقم التفويض </label>
              <b-form-input
                v-model="authorizationNumber"
                id="authorizationNumber"
                type="number"
              ></b-form-input>
              <span
                v-if="authorizationNumber && !$v.authorizationNumber.numeric"
                class="invalid-feedback d-block"
                >رقم التفويض يجب أن يحتوي على أرقام فقط</span
              >
              <!-- <p class="error" v-if="!$v.authorizationNumber.minLength">
                يجب ان لا يقل عن ١٠ ارقام
              </p> -->
            </div>
          </div>

          <!-- builtAt -->
          <div class="col-sm-4">
            <div class="form">
              <label for="builtAt"> تاريخ إنشاء العقار </label>
              <multiselect
                :options="years"
                v-model="builtAt"
                :show-labels="false"
                placeholder="اختر تاريخ إنشاء العقار"
                value-field="year"
                text-field="year"
                label="year"
                track-by="year"
              ></multiselect>
            </div>
          </div>

          <!-- Age -->
          <div class="col-sm-4" v-show="typeData && typeData.age">
            <div class="form">
              <label for="age"> عمر العقار </label>
              <b-form-input
                :disabled="true"
                v-model.number="age"
                id="age"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- Rooms -->
          <div
            class="col-sm-4"
            v-show="typeData && typeData.rooms"
            :class="{ 'has-error': $v.numberOfRooms.$error }"
          >
            <div class="form">
              <label> عدد الغرف {{ unitsText }} </label>
              <b-form-input
                v-model.number="numberOfRooms"
                id="numberOfRooms"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- width -->
          <div
            class="col-sm-4"
            v-show="typeData && typeData.width"
            :class="{ 'has-error': $v.width.$error }"
          >
            <div class="form">
              <label>عرض العقار</label>
              <b-form-input
                v-model.number="width"
                id="width"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- length -->
          <div
            class="col-sm-4"
            v-show="typeData && typeData.length"
            :class="{ 'has-error': $v.length.$error }"
          >
            <div class="form">
              <label>طول العقار</label>
              <b-form-input
                v-model.number="length"
                id="length"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- Number of Floor -->
          <div class="col-sm-4" v-show="typeData && typeData.floor">
            <div class="form">
              <label>
                {{ floorText }}
                <span>(اختياري)</span>
              </label>
              <b-form-input
                v-model.number="floor"
                id="floor"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- Number Of Units -->
          <div class="col-sm-4" v-show="typeData && typeData.numberOfUnit">
            <div class="form">
              <label>
                عدد الوحدات
                <span>(اختياري)</span>
              </label>
              <b-form-input
                v-model.number="numberOfUnit"
                id="numberOfUnit"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- Halls -->
          <div class="col-sm-4" v-show="typeData && typeData.halls">
            <div class="form">
              <label>
                عدد الصالات {{ unitsText }}
                <span>(اختياري)</span>
              </label>
              <b-form-input
                v-model.number="numberOfLivingRoom"
                id="numberOfLivingRoom"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- Baths -->
          <div class="col-sm-4" v-show="typeData && typeData.baths">
            <div class="form">
              <label>
                دورات المياة {{ unitsText }}
                <span>(اختياري)</span>
              </label>
              <b-form-input
                v-model.number="numberOfBathRoom"
                id="numberOfBathRoom"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- number Of Kitchen Unit -->
          <div
            class="col-sm-4"
            v-show="typeData && typeData.numberOfKitchenUnit"
          >
            <div class="form">
              <label>
                عدد المطابخ {{ unitsText }}
                <span>(اختياري)</span>
              </label>
              <b-form-input
                v-model.number="numberOfKitchenUnit"
                id="numberOfKitchenUnit"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>

          <!-- selectedSides -->
          <div class="col-sm-6" v-if="typeData && typeData.selectedSides">
            <div class="form">
              <label> {{ textBasedOnPropType }}: </label>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  class="checkboxes in-row margin-bottom-20"
                  id="checkbox-group-1"
                  v-model="selectedSides"
                  :options="selectedSidesArray"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-1"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>

          <!-- streetWidth -->
          <div
            class="col-sm-6"
            :class="{ 'has-error': $v.streetWidth.$error }"
            v-show="typeData && typeData.streetWidth"
          >
            <div class="form">
              <label>
                عرض الشارع
                <span>(بالأمتار)</span>
              </label>
              <b-form-input
                v-model.number="streetWidth"
                type="number"
                @input.native="preventNegativeNumber"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>

      <!-- Features -->
      <template v-if="getRealEstateFeatures.length && !isLand && !isStorehouse">
        <h4 class="submit-section-header">خصائص العقار</h4>
        <div class="submit-section">
          <div class="row with-forms">
            <div class="col-sm-12">
              <div class="form">
                <b-form-checkbox-group
                  class="checkboxes in-row margin-bottom-20"
                  v-model="features"
                  :options="getRealEstateFeatures"
                  value-field="_id"
                  text-field="nameAr"
                ></b-form-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </template>

      <h4 class="submit-section-header">تفاصيل إضافية</h4>
      <div class="submit-section margin-bottom-0">
        <div class="row with-forms">
          <div class="col-sm-12">
            <div class="form">
              <b-form-textarea
                id="notes"
                v-model="notes"
                class="WYSIWYG"
                cols="40"
                rows="3"
                spellcheck="true"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- <b-alert show variant="danger" v-if="isStepValid">من فضلك تأكد من ملئ جميع الحقول المطلوبة</b-alert> -->
      <!-- <br> <br> type: {{type}} -->
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { realEstateService } from '@/services'
import config from '@/config'
import { requiredIf, numeric, helpers } from 'vuelidate/lib/validators'
const validateIf = (prop, validator) =>
  helpers.withParams({ type: 'validatedIf', prop }, function (value, parentVm) {
    return helpers.ref(prop, this, parentVm) ? validator(value) : true
  })
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: ['type', 'property'],
  data() {
    return {
      authorizationNumber: '',
      advertiserType: '',
      builtAt: null,
      floor: null,
      numberOfUnit: null,
      numberOfRooms: null,
      numberOfBathRoom: null,
      numberOfLivingRoom: null,
      numberOfKitchenUnit: null,
      notes: '',
      features: [],
      //showFeatures:true,
      featuresItems: [],
      selectedSides: [],
      streetWidth: '',
      length: null,
      width: null,
      completedFields: [],
      floorText: 'رقم الدور',
      unitsText: '',
      advertiserTypes: [
        {
          _id: 1,
          name: 'مالك',
          code: 'owner'
        },
        {
          _id: 2,
          name: 'مفوض',
          code: 'commissar'
        }
      ]
    }
  },
  watch: {
    advertiserType(newVal) {
      if (newVal.code === 'owner') {
        this.authorizationNumber = -1
      } else {
        this.authorizationNumber = ''
      }
    },
    type(type) {
      if (type) {
        let newType = this.getConfigNameById('realEstateTypes', 'En', type),
          oldType
        if (this.pageId != 'new') {
          oldType = this.property.type.nameEn
        }
        //if the CM change the type of property to land  when he updated
        if (newType === 'land' || newType === 'Storehouse') {
          this.floor = null
          this.numberOfUnit = null
          this.numberOfRooms = null
          this.numberOfBathRoom = null
          this.numberOfLivingRoom = null
          this.numberOfKitchenUnit = null
          this.features = []
          this.featuresItems = []
        }
        if (newType !== 'land' && newType !== 'Storehouse') {
          this.featuresItems = this.realEstateFeatures
        }
        if (
          this.pageId == 'new' ||
          (this.pageId != 'new' && oldType != newType)
        ) {
          this.floor = null
          this.numberOfUnit = null
          this.numberOfRooms = null
          this.numberOfBathRoom = null
          this.numberOfLivingRoom = null
          this.numberOfKitchenUnit = null
          this.authorizationNumber = ''
          this.length = null
          this.width = null
          this.features = []
          this.featuresItems = []
        }

        if (
          newType === 'building' ||
          newType === 'Tower' ||
          newType === 'Building'
        ) {
          this.floorText = 'عدد الأدوار'
          this.unitsText = 'في كل وحدة'
        } else if (newType === 'villa') {
          this.floorText = 'عدد الأدوار'
          this.unitsText = ''
        } else if (newType === 'compound') {
          this.floorText = 'عدد الأدوار في كل وحدة'
          this.unitsText = 'في كل وحدة'
        } else {
          this.floorText = 'رقم الدور'
          this.unitsText = ''
        }
      }
    },
    property() {
      if (this.property) {
        if (this.property.builtAt) {
          const builtAtDate = new Date(this.property.builtAt)
          this.builtAt = { year: builtAtDate.getFullYear() }
        }
        if (this.property.floor) {
          this.floor = this.property.floor
        }
        if (this.property.numberOfUnit) {
          this.numberOfUnit = this.property.numberOfUnit
        }
        if (this.property.numberOfRooms) {
          this.numberOfRooms = this.property.numberOfRooms
        }
        if (this.property.numberOfBathRoom) {
          this.numberOfBathRoom = this.property.numberOfBathRoom
        }
        if (this.property.numberOfLivingRoom) {
          this.numberOfLivingRoom = this.property.numberOfLivingRoom
        }
        if (this.property.numberOfKitchenUnit) {
          this.numberOfKitchenUnit = this.property.numberOfKitchenUnit
        }
        if (this.property.width) {
          this.width = this.property.width
        }
        if (this.property.length) {
          this.length = this.property.length
        }
        if (this.property.authorizationNumber) {
          this.authorizationNumber = this.property.authorizationNumber
        }
        if (this.property.notes) {
          this.notes = this.property.notes
        }

        if (this.property.features) {
          this.features = this.property.features.map((item) => item._id)
        }

        if (this.property.selectedSides) {
          this.selectedSides = this.property.selectedSides
        }
        if (this.property.streetWidth) {
          this.streetWidth = this.property.streetWidth
        }
        if (Object.prototype.hasOwnProperty.call(this.property, 'fromOwner')) {
          const advertiserTypeCode = this.property.fromOwner
            ? 'owner'
            : 'commissar'
          const selectedAdvertiserType = this.advertiserTypes.find(
            (type) => type.code === advertiserTypeCode
          )
          this.advertiserType = selectedAdvertiserType
        }
      }
    },
    // Watch Fields to calcule the Complete Percentage --------------------
    builtAt(val) {
      this.addToCompleteFields('builtAt', val.year)
      this.removeFromCompleteFields('builtAt', val.year)
    },
    floor(val) {
      this.addToCompleteFields('floor', val)
      this.removeFromCompleteFields('floor', val)
    },
    numberOfUnit(val) {
      this.addToCompleteFields('numberOfUnit', val)
      this.removeFromCompleteFields('numberOfUnit', val)
    },
    numberOfRooms(val) {
      this.addToCompleteFields('numberOfRooms', val)
      this.removeFromCompleteFields('numberOfRooms', val)
    },
    numberOfBathRoom(val) {
      this.addToCompleteFields('numberOfBathRoom', val)
      this.removeFromCompleteFields('numberOfBathRoom', val)
    },
    width(val) {
      this.addToCompleteFields('width', val)
      this.removeFromCompleteFields('width', val)
    },
    length(val) {
      this.addToCompleteFields('length', val)
      this.removeFromCompleteFields('length', val)
    },
    authorizationNumber(val) {
      this.addToCompleteFields('authorizationNumber', val)
      this.removeFromCompleteFields('authorizationNumber', val)
    },
    numberOfLivingRoom(val) {
      this.addToCompleteFields('numberOfLivingRoom', val)
      this.removeFromCompleteFields('numberOfLivingRoom', val)
    },
    numberOfKitchenUnit(val) {
      this.addToCompleteFields('numberOfKitchenUnit', val)
      this.removeFromCompleteFields('numberOfKitchenUnit', val)
    },
    notes(val) {
      this.addToCompleteFields('notes', val)
      this.removeFromCompleteFields('notes', val)
    },
    features(val) {
      this.addToCompleteFields('features', val)
      this.removeFromCompleteFields('features', val)
    },

    selectedSides(val) {
      this.addToCompleteFields('selectedSides', val)
      this.removeFromCompleteFields('selectedSides', val)
    },

    streetWidth(val) {
      this.addToCompleteFields('streetWidth', val)
      this.removeFromCompleteFields('streetWidth', val)
    },

    completedFields(fields) {
      let length = fields.length >= 3 ? 3 : fields.length
      let stepPercentage = (25 / 3) * length
      this.setStep3_Percentage(stepPercentage)
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'realEstateFeatures', 'user']),
    ...mapGetters('accountState', ['getConfigNameById', 'getConfigIdByName']),
    dataToSend() {
      const data = {
        authorizationNumber: this.$data.authorizationNumber,
        fromOwner: this.advertiserType.code === 'owner' ? true : false,
        ...(this.$data.builtAt?.year && {
          builtAt: String(this.$data.builtAt?.year)
        }),
        floor: this.$data.floor,
        numberOfUnit: this.$data.numberOfUnit,
        numberOfRooms: this.$data.numberOfRooms,
        numberOfBathRoom: this.$data.numberOfBathRoom,
        numberOfLivingRoom: this.$data.numberOfLivingRoom,
        numberOfKitchenUnit: this.$data.numberOfKitchenUnit,
        notes: this.$data.notes,
        features: this.$data.features,
        featuresItems: this.$data.featuresItems,
        selectedSides: this.$data.selectedSides,
        streetWidth: this.$data.streetWidth,
        length: this.$data.length,
        width: this.$data.width,
        completedFields: this.$data.completedFields,
        floorText: this.$data.floorText,
        unitsText: this.$data.unitsText,
        account: { ...this.account }
      }
      return data
    },
    account() {
      const data = {
        id: this.user?._id,
        name: this.user?.name,
        phoneNumber: this.user?.phoneNumber,
        email: this.user?.email,
        clientType: this.user?.clientType,
        licenseNumber: this.user?.licenseNumber,
        company: this.user?.company,
        userType: this.user?.type
      }
      return data
    },
    textBasedOnPropType() {
      return this.isLand
        ? 'الأرض مُطلة على شارع من الناحية'
        : 'العقار مُطل على شارع من الناحية'
    },
    isAuthorizedClient() {
      return this.advertiserType?.code === 'commissar' ? true : false
    },
    config() {
      return config
    },
    typeData() {
      let nameEn = this.getConfigNameById('realEstateTypes', 'En', this.type)
      if (nameEn === 'Chalet' || nameEn === 'Guest house') {
        nameEn = 'flat'
      } else if (nameEn === 'workshop') {
        nameEn = 'office'
      } else if (nameEn === 'Storehouse') {
        nameEn = 'land'
      } else if (nameEn === 'Tower' || nameEn === 'Building') {
        nameEn = 'building'
      }
      return config.typesFields[nameEn]
    },
    pageId() {
      return this.$route.params.id
    },
    getRealEstateFeatures() {
      return this.realEstateFeatures
    },
    age() {
      const currentDate = new Date()
      return this.builtAt?.year
        ? currentDate.getFullYear() - this.builtAt.year
        : ''
    },
    isLand() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'land'
      )
    },
    isStorehouse() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) ===
        'Storehouse'
      )
    },
    years() {
      const years = []
      for (let year = new Date().getFullYear(); year >= 1900; year--) {
        years.push({ year })
      }
      return years
    },
    selectedSidesArray() {
      return Object.values(this.typeData.selectedSides)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.featuresItems = this.realEstateFeatures
    })
  },
  validations: {
    builtAt: {
      required: requiredIf(function () {
        return this.typeData?.builtAt
      })
    },
    authorizationNumber: {
      numeric: validateIf('isAuthorizedClient', numeric),
      required: requiredIf(function () {
        return this.typeData?.authorizationNumber && this.isAuthorizedClient
      })
      // minLength: validateIf('isAuthorizedClient', minLength(10))
    },
    numberOfRooms: {
      required: requiredIf(function () {
        return this.typeData?.rooms
      })
    },
    length: {
      required: requiredIf(function () {
        return this.typeData?.length
      })
    },
    width: {
      required: requiredIf(function () {
        return this.typeData?.width
      })
    },
    streetWidth: {
      required: requiredIf(function () {
        return this.typeData?.streetWidth
      })
    },
    advertiserType: {
      required: requiredIf(function () {
        return this.typeData?.selectedAdvertiserType
      })
    },
    form: [
      'builtAt',
      'numberOfRooms',
      'width',
      'length',
      'streetWidth',
      'advertiserType',
      'authorizationNumber'
    ]
  },
  methods: {
    ...mapMutations('realEstateState', ['setStep3_Percentage']),
    preventNegativeNumber(e) {
      const { target } = e
      if (!/^\d+$/.test(target.value)) target.value = ''
    },
    addToCompleteFields(name, fieldVal) {
      if (fieldVal && !this.completedFields.includes(name)) {
        this.completedFields.push(name)
      }
    },
    removeFromCompleteFields(name, val) {
      if ((val == '' || val == 0) && this.completedFields.includes(name)) {
        let index = this.completedFields.indexOf(name)
        this.completedFields.splice(index, 1)
      }
    },
    async validateAdvertiser() {
      try {
        const body = {
          userType: this.user.clientType,
          authorizationNumber: this.dataToSend.authorizationNumber,
          fromOwner: this.dataToSend.fromOwner,
          adNumber: +this.user.registrationNumber
        }
        if (this.user.clientType === 'individual')
          body.idNumber = this.user.idNumber
        else body.licenseNumber = this.user.licenseNumber

        const { data } = await realEstateService.validateAdvertiser(body)

        if (typeof data.isValid === 'object') {
          this.$toasted.show(data.isValid.errorMsg_AR, {
            duration: 4000,
            type: 'error',
            icon: 'fa-times'
          })
          return false
        }
        return true
      } catch (err) {
        console.log(err)
      }
    },
    async validate() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        this.$emit('on-validate', this.dataToSend, isValid)
        return isValid
      } else {
        if (!this.isAuthorizedClient) delete this.dataToSend.authorizationNumber
        // If property empty string will not send
        for (const key in this.dataToSend) {
          if (this.dataToSend[key] === '') {
            delete this.dataToSend[key]
          }
        }

        if (this.isAuthorizedClient) {
          let validateStatus = false
          await this.validateAdvertiser().then((status) => {
            validateStatus = status
          })
          if (validateStatus) {
            this.$emit('on-validate', this.dataToSend, isValid)
            return isValid
          }
        }
        this.$emit('on-validate', this.dataToSend, isValid)
        return isValid
      }
    }
  }
}
</script>
