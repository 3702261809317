<template>
  <div class="thumbnail-wrap mainImage">
    <img class="preview thumbnail" :class="'image-'" :src="imgSrc" />
    <div class="thumbnail-info">
      <span class="image-name">{{ imgName }}</span>
      <div>
        <div v-if="isLoading" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span v-if="imageUploaded" class="remove-btn is-main-img hi-check" v-b-tooltip.hover
          title="تم رفع الصورة"></span>
        <span v-if="imageUploaded" class="remove-btn hi-trash" v-on:click="removeImage" v-b-tooltip.hover
          title="حذف الصورة"></span>
      </div>
    </div>
  </div>
</template>
<script>
  import store from "@/store";
  import {
    realEstateService
  } from "@/services";
  export default {
    props: ["image"],
    data() {
      return {
        imgSrc: "",
        imgName: "",
        isLoading: true,
        imageUploaded: false,
        imageKey: "",
        smallImageKey: "",
        key: "",
        fileId: "",
        uploadPath: "",
      };
    },
    created() {
      if (this.image) {
        this.getImage();
      }
      console.log("new image created");
    },
    methods: {
      uploadImage(form) {
        this.isLoading = true;
        realEstateService.uploadRealEstateImage(form).then((response) => {
          if (response.data.success) {
            const image = response.data.success;
            this.imageUploaded = true;
            this.isLoading = false;
            this.key = image.key;
            // populate image keys
            this.imageKey = image.normalImage.key;
            this.smallImageKey = image.smallImage.key;
            // emit event to parent component that image has been uploaded
            this.$emit("on-upload", {
              normalImage: image.normalImage,
              smallImage: image.smallImage,
            });
            // push new image to preview image store
            this.uploadPath = image.normalImage.path;
            store.commit(
              "realEstateState/previewedImages",
              image.normalImage.path
            );
          }
        });
      },
      getImage() {
        // reset Preveiw Images
        this.fileId = this.image.id;
        // first we need to check for the image type
        // if the image is of type blob then read it as a file and upload to serve
        // else it is an already uploaded image then just populate the image data
        if (this.image instanceof Blob) {
          if (/\.(jpe?g|png|gif)$/i.test(this.image.name)) {
            this.imgName = this.image.name;
            //Create a new FileReader object
            let reader = new FileReader();
            //Add an event listener for when the file has been loaded to update the src on the file preview.
            reader.addEventListener(
              "load",
              function () {
                this.imgSrc = reader.result;
                // upload image to server
                var form = new FormData();
                form.append("image", this.image);
                this.uploadImage(form);
              }.bind(this),
              false
            );
            //Read the data for the file in through the reader. When it has been loaded, we listen to the event propagated and set the image src to what was loaded from the reader.
            reader.readAsDataURL(this.image);
          }
        } else {
          // image is already uploaded (editing property)
          this.imgSrc = this.image.normalImage.path;
          this.imgName = this.image.normalImage.path.split("/")[3];
          this.imageKey = this.image.normalImage.key;
          this.smallImageKey = this.image.smallImage.key;
          this.imageUploaded = true;
          this.isLoading = false;
          store.commit("realEstateState/previewedImages", this.imgSrc);
        }
      },
      removeImage() {
        this.$dialog
          .confirm("هل أنت متأكد من حذف صورة العقار؟", {
            loader: true,
            okText: "تأكيد الحذف",
            cancelText: "إلغاء",
            customClass: "delete-dialog",
          })
          .then((dialog) => {
            dialog.close();
            realEstateService
              .deleteRealEstateImage(this.imageKey, this.smallImageKey)
              .then((response) => {
                if (response.data.success) {
                  this.$emit("on-remove-upload", this.fileId, this.imageKey);
                }
              });
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 17px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      margin: 0 8px 0 8px;
      border: 4px solid #52ab75;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #52ab75 transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
  /* .lds-ring  */
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>