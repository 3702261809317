<template>
  <div id="step-1" class="property-step" :class="`type-${propertyType}`">
    <!-- <h2 class="property-step__title">اختر نوع العقار</h2> -->
    <!--first step choose property type -->
    <h4
      class="submit-section-header d-flex justify-content-between align-items-center"
    >
      <span>نوع العقار</span>
    </h4>
    <div class="submit-section">
      <div class="row with-forms" :class="{ 'has-error': $v.type.$error }">
        <b-form-radio-group
          id="types-radio"
          class="radio-group radio-group--center"
        >
          <b-form-radio
            v-for="item in configs.realEstateTypes"
            :key="item._id"
            v-model="type"
            :value="item._id"
            class="radio radio--flat radio--flat--regular property-type"
            :id="item.nameEn"
            >{{ item.nameAr }}</b-form-radio
          >
        </b-form-radio-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-4" v-show="type !== ''">
        <div class="col-inner-wrap">
          <h4 class="submit-section-header d-flex justify-content-between">
            <span>حالة العقار</span>
          </h4>
          <div class="submit-section">
            <div :class="{ 'has-error': $v.status.$error }">
              <b-form-radio-group
                class="radio-group radio-group--wide radio-group--inline"
              >
                <b-form-radio
                  v-for="item in realEstateStatuses"
                  :key="item._id"
                  v-model="status"
                  :value="item._id"
                  class="radio radio--flat radio--flat--light radio--flat--wide"
                  :id="item.nameEn"
                  >{{ item.nameAr }}</b-form-radio
                >
              </b-form-radio-group>
            </div>
          </div>
        </div>

        <div
          class="col-inner-wrap realestate-purpose"
          v-if="realEstatesHasPurpose && type !== '' && status !== ''"
        >
          <h4 class="submit-section-header d-flex justify-content-between">
            <span>الغرض من العقار</span>
          </h4>
          <div
            class="submit-section"
            :class="{ 'has-error': $v.purpose.$error }"
          >
            <b-form-radio-group
              class="radio-group radio-group--wide radio-group--inline"
              v-model="purpose"
            >
              <b-form-radio
                v-for="item in purposes"
                :key="item._id"
                :value="item._id"
                class="radio radio--flat radio--flat--light radio--flat--wide"
                >{{ item.nameAr }}</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </div>

        <div class="col-inner-wrap realestate-purpose" v-if="isForRent">
          <h4 class="submit-section-header d-flex justify-content-between">
            <span>مدة الإيجار</span>
          </h4>
          <div class="submit-section">
            <div :class="{ 'has-error': $v.payType.$error }">
              <b-form-radio-group
                id="payType-radios"
                class="radio-group radio-group--wide radio-group--inline"
                v-model="payType"
              >
                <b-form-radio
                  v-for="item in payTypeOptions"
                  :key="`payType-${item.value}`"
                  :value="item.value"
                  :id="`payType-${item.value}`"
                  class="radio radio--flat radio--flat--light radio--flat--wide"
                  >{{ item.text }}</b-form-radio
                >
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-6 mb-4 price-space-fields"
        v-show="type !== '' && status !== ''"
      >
        <div class="col-inner-wrap">
          <h4 class="submit-section-header">سعر و مساحة العقار</h4>
          <div class="submit-section">
            <div class="row with-forms">
              <!-- Price -->
              <div class="col-sm-6 price-column">
                <div class="form" :class="{ 'has-error': $v.price.$error }">
                  <b-form-input
                    v-model.number="price"
                    id="price"
                    @input.native="preventNegativeNumber"
                    type="number"
                    :placeholder="payTypeText"
                    class="m-0"
                  ></b-form-input>
                  <span class="unit-label">
                    ريال
                    <span v-if="isForRent && payType !== ''">
                      /
                      {{ payTypeOptions[payType].text }}</span
                    >
                  </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div id="negotiable" v-if="!isLand && !isStorehouse">
                    <b-form-checkbox
                      ref="negotiableRef"
                      v-model="negotiable"
                      size="lg"
                      name="check-button"
                      switch
                    >
                      قابل للتفاوض
                    </b-form-checkbox>
                  </div>
                  <div id="allTheFutures" v-if="isStore">
                    <b-form-checkbox
                      ref="allTheFutures"
                      v-model="allTheFutures"
                      size="lg"
                      name="check-button"
                      switch
                    >
                      للتقبيل
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <!-- Area -->
              <div class="col-sm-6">
                <div class="form">
                  <!-- <label for="area">
                                        مساحة العقار
                                    </label> -->
                  <b-form-input
                    v-model.number="space"
                    id="area"
                    type="number"
                    @input.native="preventNegativeNumber"
                    placeholder="مساحة العقار"
                    class="m-0"
                  ></b-form-input>
                  <span class="unit-label">متر</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-6 mb-4"
        style="margin-top: 10px"
        v-if="isForRent && (isFlat || isFloor || isChalet || isGuestHouse)"
      >
        <div class="col-inner-wrap">
          <h4 class="submit-section-header d-flex justify-content-between">
            <span>نوع السكن</span>
          </h4>
          <div class="submit-section">
            <div :class="{ 'has-error': $v.populationType.$error }">
              <b-form-radio-group
                class="radio-group radio-group--wide radio-group--inline"
                v-model="populationType"
              >
                <b-form-radio
                  v-for="item in configs.realEstatePopulationType"
                  :key="item._id"
                  :value="item._id"
                  :id="item.nameEn"
                  class="radio radio--flat radio--flat--light radio--flat--wide"
                  >{{ item.nameAr }}</b-form-radio
                >
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations, mapState } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import config from '@/config'

export default {
  props: ['property'],
  components: {},
  data() {
    return {
      type: '',
      status: '', // ''
      payType: '',
      purpose: '', // ''
      populationType: '',
      tags: [],
      price: '',
      space: '',
      completedFields: [],
      propertyType: ''
    }
  },
  validations: {
    type: {
      required
    },
    status: {
      required
    },
    payType: {
      required: requiredIf(function () {
        return this.isForRent
      })
    },
    populationType: {
      required: requiredIf(function () {
        return (
          this.isForRent &&
          (this.isFlat || this.isFloor || this.isChalet || this.isGuestHouse)
        )
      })
    },
    purpose: {
      required: requiredIf(function () {
        return config.typesPurposeIsRequired.includes(
          this.getConfigNameById('realEstateTypes', 'En', this.type)
        )
      })
    },
    price: {
      required
    },
    form: ['type', 'status', 'purpose', 'price', 'payType', 'populationType']
  },
  computed: {
    ...mapState('accountState', ['configs']),
    ...mapState('realEstateState', ['completePercentage']),
    ...mapGetters('accountState', ['getConfigNameById', 'getConfigIdByName']),
    id() {
      return this.$route.params.id
    },
    payTypeText() {
      return this.status == this.getConfigIdByName('realEstateStatus', 'rent')
        ? 'قيمة الإيجار'
        : 'سعر العقار'
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    purposes() {
      if (!this.isLand && !this.isStorehouse) {
        return this.configs.realEstatePurpose.filter((purpose) => {
          return (
            purpose.nameEn === 'Residential' ||
            purpose.nameEn === 'Commercial' ||
            purpose.nameEn === 'Residential Commercial'
          )
        })
      } else if (this.isStorehouse) {
        return this.configs.realEstatePurpose.filter(
          (purpose) =>
            purpose.nameEn !== 'Residential' &&
            purpose.nameEn !== 'Residential Commercial'
        )
      } else if (this.isLand) {
        return this.configs.realEstatePurpose.filter(
          (purpose) => purpose.nameEn !== 'Residential Commercial'
        )
      }
      return this.configs.realEstatePurpose
    },
    negotiable: {
      get() {
        return false
      },
      set(val) {
        if (this.tags.includes('negotiable')) {
          const index = this.tags.indexOf('negotiable')
          this.tags.splice(index, 1)
        } else {
          this.tags.push('negotiable')
        }
        return val
      }
    },
    allTheFutures: {
      get() {
        return false
      },
      set(val) {
        if (this.tags.includes('allTheFutures')) {
          const allTheFuturesIndex = this.tags.indexOf('allTheFutures')
          this.tags.splice(allTheFuturesIndex, 1)
        } else {
          this.tags.push('allTheFutures')
        }
        return val
      }
    },
    realEstateStatuses() {
      return this.configs.realEstateStatus.filter(
        (status) => status.nameEn === 'sale' || status.nameEn === 'rent'
      )
    },
    isFlat() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'flat'
      )
    },
    isFloor() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'floor'
      )
    },
    isChalet() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'Chalet'
      )
    },
    isGuestHouse() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) ===
        'Guest house'
      )
    },
    isLand() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'land'
      )
    },
    isBuilding() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) ===
        'building'
      )
    },
    isCompound() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) ===
        'compound'
      )
    },
    isStore() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'store'
      )
    },
    isStorehouse() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) ===
        'Storehouse'
      )
    },
    isForRent() {
      return (
        this.getConfigNameById('realEstateStatus', 'En', this.status) === 'rent'
      )
    },
    isForSale() {
      return (
        this.getConfigNameById('realEstateStatus', 'En', this.status) === 'sale'
      )
    },
    isTower() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) === 'Tower'
      )
    },
    isPremises() {
      return (
        this.getConfigNameById('realEstateTypes', 'En', this.type) ===
        'Building'
      )
    },
    realEstatesHasPurpose() {
      return (
        this.isLand ||
        this.isBuilding ||
        this.isCompound ||
        this.isStorehouse ||
        this.isTower ||
        this.isPremises
      )
    }
  },
  watch: {
    property() {
      if (this.property) {
        if (this.property.type) {
          this.type = this.property.type._id
        }
        if (this.property.status) {
          this.status = this.property.status._id
        }
        if (this.property.purpose) {
          this.purpose = this.property.purpose?._id
        }
        if (this.property.populationType) {
          this.populationType = this.property.populationType._id
        }
        if (this.property.price) {
          this.price = this.property.price
        }
        if (
          this.property.payType === 0 ||
          this.property.payType === 1 ||
          this.property.payType === 2
        ) {
          this.payType = this.property.payType
        }
        if (this.property.space) {
          this.space = this.property.space
        }
        if (this.property.tags) {
          this.tags = this.property.tags
        }
      }
    },
    payType(val) {
      this.addToCompleteFields('payType', val)
    },
    type(val, oldVal) {
      let type = this.getConfigNameById('realEstateTypes', 'En', this.type)
      let oldType = this.getConfigNameById('realEstateTypes', 'En', oldVal)

      const isNewTypeHasPurpose =
        type === 'compound' ||
        type === 'building' ||
        type === 'Storehouse' ||
        type === 'Tower' ||
        type === 'Building'
      if (oldType === 'land' && isNewTypeHasPurpose) {
        this.purpose = ''
      }
      // set hidden purpose
      // this.purpose = '';
      if (config.typesForResidential.includes(type)) {
        this.purpose = this.getConfigIdByName(
          'realEstatePurpose',
          'Residential'
        )
      } else if (config.typesForCommercial.includes(type)) {
        this.purpose = this.getConfigIdByName('realEstatePurpose', 'Commercial')
      }
      // used to add class name in step wrapper
      this.propertyType = type

      this.addToCompleteFields('type', val)
    },
    status(val) {
      if (val == this.getConfigIdByName('realEstateStatus', 'sale'))
        this.populationType = null
      this.addToCompleteFields('status', val)
    },
    price(val) {
      this.addToCompleteFields('price', val)
      this.removeFromCompleteFields('price', val)
    },
    space(val) {
      this.addToCompleteFields('space', val)
      this.removeFromCompleteFields('space', val)
    },
    completedFields(fields) {
      let stepPercentage = (25 / 4) * fields.length
      this.setStep1_Percentage(stepPercentage)
    }
  },
  mounted() {
    setTimeout(() => {
      this.UpDateTags()
    }, 500)
  },
  methods: {
    ...mapMutations('realEstateState', ['setStep1_Percentage']),
    preventNegativeNumber(e) {
      const { target } = e
      if (!/^\d+$/.test(target.value)) target.value = ''
    },
    addToCompleteFields(name, fieldVal) {
      if (
        fieldVal &&
        this.completedFields &&
        !this.completedFields.includes(name)
      ) {
        this.completedFields.push(name)
      }
    },
    removeFromCompleteFields(name, val) {
      if ((val == '' || val == 0) && this.completedFields.includes(name)) {
        let index = this.completedFields.indexOf(name)
        this.completedFields.splice(index, 1)
      }
    },
    validate() {
      this.$v.form.$touch()
      var isValid = !this.$v.form.$invalid
      let dataToSend = { ...this.$data }
      if (this.populationType === '') {
        delete dataToSend.populationType
      }
      this.$emit('on-validate', dataToSend, isValid)
      return isValid
    },
    async UpDateTags() {
      if (this.property.tags) {
        if (this.tags.includes('negotiable'))
          this.$refs.negotiableRef.checked = true
        if (this.tags.includes('allTheFutures'))
          this.$refs.allTheFutures.checked = true
      }
    }
  }
}
</script>
<style scoped>
.realestate-purpose {
  margin-top: 50px;
  margin-bottom: 10px;
}
</style>
